import braintree from "braintree";

export const state = {
  subscription: {},
};
export const actions = {
  makePayment({ commit }, payload) {
    console.log(this.getters.gateway.customer);
    this.getters.gateway.customer.create(
      {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        paymentMethodNonce: payload.nonce,
      },
      (err, result) => {
        console.log(err, result);
        if (result.success) {
          this.getters.gateway.subscription.create(
            {
              paymentMethodToken: result.customer.paymentMethods[0].token,
              planId: "frzw",
            },
            (err, result) => {
              if (err) {
                console.error(err);
                return;
              } else {
                commit("setSubscription", result.subscription);
                console.log(
                  "Subscription Status: " + result.subscription.status
                );
              }
            }
          );
        } else {
          console.error(result.message);
        }
      }
    );
  },
  getSubscription({ commit }, payload) {
    this.getters.gateway.subscription.find(payload, (err, result) => {
      if (err) {
        console.error(err);
        return;
      } else {
        commit("setSubscription", result);
        console.log("Subscription Status: " + result.status);
      }
    });
  },
  cancelSubcription({ commit }, payload) {
    this.getters.gateway.subscription.cancel(payload, (err, result) => {
      if (err) {
        console.error(err);
        return;
      } else {
        commit("setSubscription", result);
        console.log("Subscription Status: " + result.status);
      }
    });
  },
};
export const mutations = {
  setSubscription(state, subscription) {
    state.subscription = subscription;
  },
};
export const getters = {
  gateway() {
    return new braintree.BraintreeGateway({
      environment: braintree.Environment.Sandbox,
      merchantId: process.env.VUE_APP_BRAINTREE_MERCHENT_ID,
      publicKey: process.env.VUE_APP_BRAINTREE_PUBLIC_KEY,
      privateKey: process.env.VUE_APP_BRAINTREE_PRIVATE_KEY,
    });
  },
};

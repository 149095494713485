<template>
  <div>
    <p>
      Get full access to all features and more with our Seeker plan from just
      £9.99 per month (Beta offer).
    </p>
    <v-braintree
      :authorization="authorisation"
      @success="onSuccess"
      @error="onError"
    >
      <template v-slot:button="slotProps">
        <v-btn
          :disabled="BtnDisabled"
          @click="slotProps.submit"
          color="primary"
          >{{ BtnText }}</v-btn
        >
      </template>
    </v-braintree>
    <!--  <v-btn @click="getSubscription">Get</v-btn>
    <v-btn @click="cancelSubscription">Cancel</v-btn> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      BtnText: "Pay £9.99",
      BtnDisabled: false,
      authorisation: process.env.VUE_APP_BRAINTREE_AUTORISATION_KEY,
      subscriptionid: "fytx3b",
    };
  },
  methods: {
    onSuccess(payload) {
      const Payments = {
        nonce: payload.nonce,
        firstName: this.auth.User.FirstName,
        lastName: this.auth.User.LastName,
      };
      this.BtnText = "processing";
      this.BtnDisabled = true;
      this.$store.dispatch("makePayment", Payments).then((res) => {
        console.log(this.payment.subscription.status);
        console.log(res);
        this.BtnText = "Payment Successful";
      });
    },
    onError(error) {
      let message = error.message;
      alert(message);
      // Whoops, an error has occured while trying to get the nonce
    },
    getSubscription() {
      this.$store.dispatch("getSubscription", this.subscriptionid).then(() => {
        console.log(this.payment.subscription.status);
      });
    },
    cancelSubscription() {
      this.$store
        .dispatch("cancelSubcription", this.subscriptionid)
        .then(() => {
          console.log(this.payment.subscription.status);
        });
    },
  },
  computed: {
    ...mapState(["payment", "auth"]),
  },
};
</script>

<style></style>

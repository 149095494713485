<template>
  <div class="mx-auto">
    <stripe-checkout
      ref="checkoutRef"
      mode="subscription"
      :pk="publishableKey"
      :line-items="[{ price, quantity }]"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />
    <v-btn style="width: 255px" color="accent" @click="submit"
      >Upgrade now!</v-btn
    >
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  props: {
    price: String, // The id of the recurring price you created in your Stripe dashboard
    quantity: Number,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      successURL:
        "https://fixxerupper.io/profile?session_id={CHECKOUT_SESSION_ID}",
      cancelURL: "https://fixxerupper.io//profile?session_id=failure",
    };
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<template>
  <v-footer padless>
    <v-container fluid>
      <v-row>
        <v-col class="text-center" style="background-color: #14213d" cols="12">
          <p style="font-size: 14px; color: white">
            © {{ new Date().getFullYear() }} FixxerUpper
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  async beforeCreate() {
    const x = sessionStorage.getItem("userid");
    await this.$store.dispatch("loggedinuser", x);
    /*  if (this.$store.state.auth.loggedinuser == true) {
      this.$router.push({ name: "Home" });
    } */
  },
};
</script>

<style></style>

<template>
  <v-app-bar :app="app" :color="color" flat dark height="95px">
    <div class="d-flex align-center" @click="navigate" style="margin-top: 0">
      <v-img contain src="../../public/Logo_Blue.png" width="200" />
    </div>
    <v-spacer></v-spacer>
    <v-btn
      color="primary"
      text
      v-if="!this.auth.loggedinuser"
      @click="navigateLogin"
      >Login</v-btn
    >
    <v-btn
      v-if="this.auth.loggedinuser"
      color="primary"
      text
      @click="navigateLogin"
      >Welcome {{ this.auth.User.FirstName }}</v-btn
    >
      <v-btn v-if="this.auth.loggedinuser" color="primary" text @click="logout"
      >Log out</v-btn
    >
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    //
  }),
  props: {
    //
    app: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "transparent",
    },
  },
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    console.log("appbar created", this.auth.loggedinuser);
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutUser").then((res) => {
        console.log(res)
        this.$router.push({ name: "Home" });
      });
    },
    navigate() {
      this.$router.push({ name: "Home" });
    },
    navigateLogin() {
      if (this.auth.loggedinuser) {
        this.$router.push({ name: "Profile" });
      } else {
        this.$router.push({ name: "Register" });
      }
    },
  },
};
</script>

<style>
.button {
  color: #000;
}
</style>

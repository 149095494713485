<template>
  <div>
    <BaseHeaderW></BaseHeaderW>
    <div class="background">
      <v-container>
        <v-row>
          <v-col cols="9" sm="12" md="6">
            <div>
              <h3 class="heading">Avoid Costly Property Defects</h3>
              <p class="white--text mt-2">
                We help newbie DIY property developers identify defects with a
                property before viewing or making a purchase so that you can
                avoid costly refurbishment and negotiate the best deal.*
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card class="searchbar">
              <BaseSearch></BaseSearch>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container fluid>
      <v-row class="mt-7">
        <h2
          class="ml-4"
          style="text-align: center !important; margin: auto !important"
        >
          Our Services
        </h2>
        <p class="ma-3" style="text-align: center; width: 95%">
          Fixxer Upper is a website that helps people who are new to DIY
          Property development identify defects with the property before buying
          through the use of AI. We offer a wide range of services to help you
          get the most out of your property purchase.
        </p>
      </v-row>
      <v-row class="strip_white">
        <v-col cols="12" md="6">
          <h3 style="color: #14213d">Property Finder</h3>
          <div>
            When you are looking for a property, it can be difficult to know
            what to look for in terms of defects and problems. With the Fixer
            Upper Property Finder feature, you can simply enter in the location
            and type of property that you are interested in and the AI will do
            the rest. It will scan through all of the available properties in
            the UK from platforms such as Rightmove and Zoopla and identify any
            potential problems that you may encounter. This is a great way to
            make sure that you are fully informed about a property before making
            a purchase.
          </div>
        </v-col>
        <v-col cols="12" md="2"></v-col>
        <v-col cols="12" md="3">
          <v-img
            src="../../public/undraw_House_searching_re_stk8.png"
            style="width: 800px"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="strip_blue">
        <v-col cols="12" md="3">
          <v-img src="../../public/undraw_Inspection_re_tbt7.png"></v-img>
        </v-col>
        <v-col cols="12" md="2"></v-col>
        <v-col cols="12" md="6">
          <h3 style="color: #fff">Property Analysis Engine (Beta)</h3>
          <div style="color: white">
            The Property Analysis Engine is a powerful AI tool that can help you
            avoid costly mistakes when buying a property. Select a property
            you're interested in and our engine will scan it for potential
            defects. We'll provide you with a report detailing any issues we
            find. Whether you're a first-time homebuyer or a DIY Property
            investor, the Property Analysis Engine can help you make informed
            decisions about the properties you're considering.
          </div>
        </v-col>
      </v-row>
      <v-row class="strip_white">
        <v-col cols="12" md="6">
          <h3>Property Cost Calculator (Coming Soon)</h3>
          <p>
            The "Property Cost Calculator" is a great way for users to get an
            estimate of the costs associated with repairing or upgrading a
            property. This feature uses AI to help identify potential defects
            with a property, and then provides an estimate of the cost to fix
            those defects. Additionally, the calculator provides an estimate of
            the property's market value after the repairs or upgrades are made.
            This is a great tool for users who are new to DIY property
            development, as it can help them avoid overspending on a property.
          </p>
        </v-col>
        <v-col cols="12" md="2"></v-col>
        <v-col cols="12" md="3">
          <v-img src="../../public/undraw_Calculator_re_alsc.png"></v-img
        ></v-col>
      </v-row>
      <!-- <v-col cols="12" md="4">
          <h4>Property Design Engine</h4>
          <p>
            Out property designing engine showcases potential design ideas,
            remodelling concepts and floorplan designs to help design your new
            home to get the biggest bang for each buck.
          </p>
        </v-col> -->
    </v-container>
    <!-- Begin Mailchimp Signup Form -->
    <div
      id="mc_embed_signup"
      style="background-color: #14213d; padding: 1%; margin: 2% 0%"
    >
      <form
        action="https://fixxerupper.us20.list-manage.com/subscribe/post?u=ac609d01f854d7e07ab481a8f&amp;id=05e07087c4"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
        style="margin-top: 2%"
      >
        <div id="mc_embed_signup_scroll">
          <label for="mce-EMAIL" style="color: #fff; font-size: 20px"
            >Want early access to our new features? Join our Beta list for
            exclusive access</label
          >
          <input
            type="email"
            value=""
            name="EMAIL"
            class="email"
            id="mce-EMAIL"
            placeholder="email address"
            required
          />
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_ac609d01f854d7e07ab481a8f_05e07087c4"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="clear foot">
            <input
              style="background-color: #58a4b0"
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button"
            />
          </div>
          <p>
            <a
              href="http://eepurl.com/hRBgMD"
              title="Mailchimp - email marketing made easy and fun"
              ><img
                class="referralBadge ma-4"
                src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"
                width="10%"
            /></a>
          </p>
        </div>
      </form>
    </div>
    <!--End mc_embed_signup-->
    <v-container fluid>
      <h2>Become a fixxerupper</h2>
      <iframe
        src="https://www.youtube.com/embed/YWBYTgJBBU0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <span class="pa-2">
        <iframe
          src="https://www.youtube.com/embed/BbCgi3_Ynfk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </span>
      <span>
        <iframe
          src="https://www.youtube.com/embed/ys2Tj4SlIeU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </span>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>

<style scoped>
.background {
  background-image: url("../../public/background.jpg");
  background-size: cover;
  background-position: center;
  height: 600px;
  margin-top: -100px;
}
.mini {
  margin: 0% 10%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: black 2px 2px 25px;
}

.heading {
  font-family: "Righteous", cursive;
  margin-top: 35%;
  font-size: 45px;
  color: #fff;
  text-shadow: 2px 2px 7px #000000;
}
.searchbar {
  padding: 3%;
  width: 375px;
  margin-top: 35%;
}
#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 100%;
}
/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
.strip_blue {
  background-image: linear-gradient(to bottom, #09203f 0%, #09203f 100%);
  background-size: cover;
  padding: 3% 1%;
  width: 100%;
}
.strip_white {
  background-color: white;
  background-size: cover;
  padding: 3% 1%;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .heading {
    font-family: "Righteous", cursive;
    margin-top: 30%;
    font-size: 30px;
    color: #fff;
    text-shadow: 2px 2px 7px #000000;
  }
  .searchbar {
    padding: 3%;
    width: 375px;
    margin-top: 0%;
  }
  @media screen and (max-width: 992px) {
    .heading {
      font-family: "Righteous", cursive;
      margin-top: 45%;
      font-size: 25px;
      color: #fff;
      text-shadow: 2px 2px 7px #000000;
    }
  }
}
</style>

import firebase from "../../services/firebaseConfig";
import {
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
const db = getFirestore(firebase);
const axios = require("axios");

export const state = {
  SearchResults: {},
  datapartloaded: false,
};

export const mutations = {
  async setSearchResults(state, SearchResults) {
    state.SearchResults = {};
    state.SearchResults = SearchResults;
  },
  async updateSearchResults(state, val) {
    state.datapartloaded = val;
  },
};
export const actions = {
  async getSearchResultsv2({ commit }, searchData) {
    commit("updateSearchResults", false);
    return await new Promise((resolve, reject) => {
      let properties = [];
      searchData.postcode = searchData.postcode
        .toUpperCase()
        .replace(/\s/g, "");
      const docRef = doc(db, "Searches", searchData.postcode);
      getDoc(docRef)
        .then(async (docSnap) => {
          if (docSnap.data() !== undefined) {
            const querySnapshot = await getDocs(
              collection(db, "Searches", searchData.postcode, "SearchResults")
            );
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              let property = {};
              if (doc.data().IDtype === "R") {
                property = JSON.parse(doc.data().propertyData);
                property.Images = property.WebData.propertyData.images;
                property.tags = property.WebData.propertyData.tags;
                // property.type =
                //   property.WebData.analyticsInfo.analyticsProperty.propertyType;
                property.bathrooms = property.WebData.propertyData.bathrooms;
              } else if (doc.data().IDtype === "Z") {
                property = JSON.parse(doc.data().propertyData);
                let x = property.WebData.props.pageProps.listingDetails;
                if (x !== undefined) {
                  property.Images = x.propertyImage;
                  property.tags = x.tags;
                  property.subtype = x.propertyType;
                  property.bathrooms = x.adTargeting.numBaths;
                }
              }
              properties.push(property);
            });
            commit("updateSearchResults", true);
            commit("setSearchResults", properties);
            resolve(true);
          } else {
            console.log("q does not exist", docSnap.data());
            axios
              .get(
                "https://europe-west2-fixxerupper-771ce.cloudfunctions.net/Searchv3?postcode=" +
                  searchData.postcode +
                  "&radius=" +
                  25 +
                  "&results=" +
                  200
              )
              .then(async (response) => {
                console.log(response);
                commit("updateSearchResults", true);
              })
              .catch((error) => {
                alert("An Error Occured:" + error);
                window.location.reload();
              });
            setTimeout(function () {
              console.log("I am the scond log after 10 seconds");
              const querySnapshot = collection(
                db,
                "Searches",
                searchData.postcode,
                "SearchResults"
              );
              onSnapshot(querySnapshot, (response) => {
                console.log(response.docChanges());
                response.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    console.log(
                      "New Property: ",
                      change.doc.id,
                      change.doc.data().IDtype
                    );

                    // doc.data() is never undefined for query doc snapshots
                    let property = {};
                    if (change.doc.data().IDtype === "R") {
                      property = JSON.parse(change.doc.data().propertyData);
                      property.Images = property.WebData.propertyData.images;
                      property.tags = property.WebData.propertyData.tags;
                      // property.type =
                      //   property.WebData.analyticsInfo.analyticsProperty.propertyType;
                      property.bathrooms =
                        property.WebData.propertyData.bathrooms;
                    } else if (change.doc.data().IDtype === "Z") {
                      property = JSON.parse(change.doc.data().propertyData);
                      let x = property.WebData.props.pageProps.listingDetails;
                      if (x !== undefined) {
                        property.Images = x.propertyImage;
                        property.tags = x.tags;
                        property.subtype = x.propertyType;
                        property.bathrooms = x.adTargeting.numBaths;
                      }
                    }
                    properties.push(property);
                  }
                });
                commit("setSearchResults", properties);
                resolve(true);
              });
            }, 8000);
          }
        })
        .catch((err) => {
          alert(err);
          reject(false);
        });
    });
  },
};

export const getters = {
  SearchResultsCount(state) {
    return state.SearchResults.length;
  },
  compare(a, b) {
    const bandA = a.price;
    const bandB = b.price;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  },
  SearchResultsOrdered() {
    let x = state.SearchResults.sort(getters.compare);
    return x.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });
  },
};

<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      :color="snackcolor"
    >
      {{ errormessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <FormulateForm class="mt-5" v-model="formValues">
        <div class="form">
          <FormulateInput
            name="fname"
            type="text"
            placeholder="First Name"
            validation="required"
          />

          <FormulateInput
            name="lname"
            type="text"
            placeholder="Last Name"
            validation="required"
          />
          <FormulateInput
            name="email"
            type="email"
            placeholder="Email address"
            validation="required|email"
          />

          <FormulateInput
            name="password"
            type="password"
            placeholder="Your password"
            validation="required"
          />

          <FormulateInput
            name="Terms"
            type="checkbox"
            validation="required|"
            label="I
          agree to the terms and conditions"
          />
          <v-btn
            class="btn-block mx-auto"
            @click="onSubmit"
            type="submit"
            color="primary"
            :loading="isloading"
          >
            Sign Up
          </v-btn>
        </div>
      </FormulateForm>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    Title: {
      type: String,
      default: "Register your account",
    },
    Intro: {
      type: String,
      default:
        "You can place any elements you want inside a form. The inputs themselves can even be deeply nested.",
    },
  },
  data() {
    return {
      formValues: {},
      isloading: false,
      errormessage: "",
      snackbar: false,
      snackcolor: "red accent-3",
    };
  },

  methods: {
    onSubmit() {
      this.isloading = true;
      if (Object.keys(this.formValues).length === 5) {
        this.$store
          .dispatch("createUser", this.formValues)
          .then((res) => {
            console.log(res);
            this.$router.push({ name: "Profile" });
          })
          .catch((error) => {
            this.isloading = false;
            console.log(error);
            this.snackbar = true;
            this.snackcolor = "red accent-3";
            this.errormessage = error;
          });
      } else {
        this.isloading = false;
        this.snackbar = true;
        this.snackcolor = "red accent-3";
        this.errormessage = "Please complete all the fields";
      }
    },
  },
};
</script>

<style>
.form {
  margin-left: 15%;
}
.btn-block {
  width: 325px;
}
</style>

<template>
  <v-app>
    <v-main>
      <router-view />
      <BaseFooter></BaseFooter>
    </v-main>
  </v-app>
</template>
<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=G-TL7F7RG3EF"
></script>
<script>
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Fixxer Upper|DIY Property Investment made easy| AI Pro",
    // all titles will be injected into this template
    titleTemplate: "%s | Fixxer Upper",
  },
  data: () => ({
    //
  }),
  methods: {
    navigate() {
      this.$router.push({ name: "Home" });
    },
  },
  created() {
    // Hotjar Tracking Code for https://www.fixxerupper.io/
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3034383, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-TL7F7RG3EF");
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
</style>

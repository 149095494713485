<template>
  <v-app-bar :app="app" :color="color" flat dark height="95px">
    <div class="d-flex align-center" @click="navigate" style="margin-top: 0">
      <v-img contain src="../../public/logov2.png" width="200" />
    </div>
    <v-spacer></v-spacer>
    <v-btn
      v-if="!this.auth.loggedinuser"
      color="white"
      text
      @click="navigateLogin"
      >Login</v-btn
    >
    <v-btn
      v-if="this.auth.loggedinuser"
      color="white"
      text
      @click="navigateLogin"
      >Welcome {{ this.auth.User.FirstName }}</v-btn
    >
    <v-btn v-if="this.auth.loggedinuser" color="white" text @click="logout"
      >Log out</v-btn
    >
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    //
  }),
  props: {
    //
    app: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "transparent",
    },
  },
  created() {
    console.log("appbar created", this.auth.loggedinuser);
  },
  methods: {
    navigate() {
      this.$router.push({ name: "Home" });
    },
    logout() {
      this.$store.dispatch("logoutUser").then(() => {
        this.$router.push({ name: "Home" });
      });
    },
    navigateLogin() {
      if (this.auth.loggedinuser) {
        this.$router.push({ name: "Profile" });
      } else {
        this.$router.push({ name: "Register" });
      }
    },
  },
  computed: {
    ...mapState(["auth"]),
  },
};
</script>

<style>
@media screen and (max-width: 992px) {
  .setup {
    color: white;
  }
}
</style>

<template>
  <FormulateForm v-model="searchfrom">
    <div>
      <FormulateInput
        name="Postcode"
        type="text"
        label="Postcode"
        placeholder="Enter your postcode"
        validation="required"
      />
      <p> </p>
      <!-- <FormulateInput
        label="Search Radius"
        type="range"
        name="radius"
        min="1"
        max="40"
        value="45"
        validation="required|min:1|max:45"
        error-behavior="live"
        show-value="true"
      /> -->

      <!--  <div class="double-wide">
          <FormulateInput
            name="minBedrooms"
            :options="{
              all: 'Any',
              0: 'Studio',
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            }"
            type="select"
            label="Min Bedrooms"
          />
          <FormulateInput
            name="maxBedrooms"
            :options="{
              all: 'Any',
              0: 'Studio',
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
            }"
            type="select"
            validation=""
            label="Max Bedrooms"
          />
        </div>
        <div class="double-wide">
          <FormulateInput
            name="minPrice"
            :options="{
              all: 'Any',
              10000: '£10,000',
              20000: '£20,000',
              30000: '£30,000',
              40000: '£40,000',
              50000: '£50,000',
              60000: '£60,000',
              70000: '£70,000',
              80000: '£80,000',
              90000: '£90,000',
              100000: '£100,000',
              110000: '£110,000',
              120000: '£120,000',
              130000: '£130,000',
              140000: '£140,000',
              150000: '£150,000',
              160000: '£160,000',
              170000: '£170,000',
              180000: '£180,000',
              190000: '£190,000',
              200000: '£200,000',
              210000: '£210,000',
              220000: '£220,000',
              230000: '£230,000',
              240000: '£240,000',
              250000: '£250,000',
              260000: '£260,000',
              270000: '£270,000',
              280000: '£280,000',
              290000: '£290,000',
              300000: '£300,000',
              310000: '£310,000',
              320000: '£320,000',
              330000: '£330,000',
              340000: '£340,000',
              350000: '£350,000',
              360000: '£360,000',
              370000: '£370,000',
              380000: '£380,000',
              390000: '£390,000',
              400000: '£400,000',
              410000: '£410,000',
              420000: '£420,000',
              430000: '£430,000',
              440000: '£440,000',
              450000: '£450,000',
            }"
            type="select"
            label="Min Price"
          />
          <FormulateInput
            name="maxPrice"
            :options="{
              all: 'Any',
              10000: '£10,000',
              20000: '£20,000',
              30000: '£30,000',
              40000: '£40,000',
              50000: '£50,000',
              60000: '£60,000',
              70000: '£70,000',
              80000: '£80,000',
              90000: '£90,000',
              100000: '£100,000',
              110000: '£110,000',
              120000: '£120,000',
              130000: '£130,000',
              140000: '£140,000',
              150000: '£150,000',
              160000: '£160,000',
              170000: '£170,000',
              180000: '£180,000',
              190000: '£190,000',
              200000: '£200,000',
              210000: '£210,000',
              220000: '£220,000',
              230000: '£230,000',
              240000: '£240,000',
              250000: '£250,000',
              260000: '£260,000',
              270000: '£270,000',
              280000: '£280,000',
              290000: '£290,000',
              300000: '£300,000',
              310000: '£310,000',
              320000: '£320,000',
              330000: '£330,000',
              340000: '£340,000',
              350000: '£350,000',
              360000: '£360,000',
              370000: '£370,000',
              380000: '£380,000',
              390000: '£390,000',
              400000: '£400,000',
              410000: '£410,000',
              420000: '£420,000',
              430000: '£430,000',
              440000: '£440,000',
              450000: '£450,000',
            }"
            type="select"
            label="Max Price"
          />
        </div>
        <FormulateInput
          name="Proptype"
          type="select"
          label="Property Type"
          :options="{
            Flats: 'Flats',
            Houses: 'Houses',
            Bungalows: 'Bungalows',
          }"
          validation="required"
        /> -->
    </div>
    <FormulateInput
      id="searchbutton"
      class="mt-2"
      type="button"
      label="Search"
      @click="onSubmit"
    />
  </FormulateForm>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    searchfrom: {},
    value: "",
  }),
  methods: {
    onSubmit() {
      axios
        .get(
          "https://api.postcodes.io/postcodes/" +
            this.searchfrom.Postcode +
            "/validate"
        )
        .then((response) => {
          console.log(response.data.result);
          if (response.data.result === true) {
            console.log("Navigate to search results");
            this.$router.push({
              name: "SearchResults",
              params: { search: this.searchfrom.Postcode },
              query: {
                propType: this.searchfrom.Proptype,
                minBed: this.searchfrom.minBedrooms,
                maxBed: this.searchfrom.maxBedrooms,
                minPrice: this.searchfrom.minPrice,
                maxPrice: this.searchfrom.maxPrice,
                radius: this.searchfrom.radius,
              },
            });
          } else {
            alert(
              "Something is not right, are you sure you entered a valid postcode?"
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.login-form {
  padding: 2em;
  border-radius: 0.5em;
  max-width: 400px;
  box-sizing: border-box;
}
.form-title {
  margin-top: 0;
}
.login-form::v-deep .formulate-input .formulate-input-element {
  max-width: none;
  background-color: #fff;
  margin-top: 5%;
}
#searchbutton {
  width: 350px;
}
@media (min-width: 420px) {
  .double-wide {
    display: flex;
  }
  .double-wide .formulate-input {
    flex-grow: 1;
    width: calc(50% - 0.5em);
  }
  .double-wide .formulate-input:first-child {
    margin-right: 0.5em;
  }
  .double-wide .formulate-input:last-child {
    margin-left: 0.5em;
  }
}
</style>

import firebase from "../../services/firebaseConfig";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  collection,
  query,
  where,
  addDoc,
  getDocs,
  updateDoc,
  doc,
  // getDoc,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const db = getFirestore(firebase);
const auth = getAuth();
const provider = new GoogleAuthProvider();

export const state = {
  User: {},
  UserId: "",
  loggedinuser: false,
};
export const actions = {
  createUser({ commit }, User) {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, User.email, User.password)
        .then(async (user) => {
          const x = user.user.uid;
          const fixxerdb = collection(db, "Fixxers");
          addDoc(fixxerdb, {
            FirstName: User.fname,
            LastName: User.lname,
            Type: "User",
            UID: x,
            isPremium: false,
            Trial: false,
            Email: User.email,
            PaymentUserId: "",
            PaymentSubscriptionId: "",
            PaymentPlanId: "",
            Enabled: true,
            Terms: User.Terms,
          });
          const q = query(fixxerdb, where("UID", "==", x));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            commit("SET_USER", doc.data());
            commit("SET_USERID", x);
            commit("SET_LOGGEDINUSER", true);
            resolve(doc);
          });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  loginUser({ commit }, User) {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, User.email, User.password)
        .then(async (userCredential) => {
          // Signed in
          const loggedinuser = userCredential.user;
          const fixxerdb = collection(db, "Fixxers");
          const q = query(fixxerdb, where("UID", "==", loggedinuser.uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            commit("SET_USER", doc.data());
            commit("SET_USERID", loggedinuser.uid);
            commit("SET_LOGGEDINUSER", true);
            resolve("success");
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          reject(errorCode);
        });
    });
  },
  async loggedinuser({ commit }, uid) {
    const fixxerdb = collection(db, "Fixxers");
    const q = query(fixxerdb, where("UID", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      commit("SET_USER", doc.data());
      commit("SET_USERID", uid);
      commit("SET_LOGGEDINUSER", true);
    });
  },
  googleLogin({ commit }) {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(token);
        // The signed-in user info.
        const user = result.user;
        commit("SET_USERID", user.uid);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, email, credential);
        // ...
      });
  },
  async logoutUser({ commit }) {
    console.log(auth);
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.clear();
        sessionStorage.clear();
        commit();
        return "done";
      })
      .catch((error) => {
        // An error happened.
        return "Error", error;
      });
  },
  async subscriptionActive({ commit }, payload) {
    const fixxerdb = collection(db, "Fixxers");
    console.log(payload);
    const q = query(fixxerdb, where("UID", "==", payload.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (data) => {
      const propertyUser = await doc(db, "Fixxers", data.id);
      // Set the "capital" field of the city 'DC'
      await updateDoc(propertyUser, {
        isPremium: true,
        PaymentSessionId: payload.sessionID,
        Trial: true,
      });
    });
    // const mydoc = await getDoc(q);
    // Set the "capital" field of the city 'DC'
    // await updateDoc(mydoc, { isPremium: true });
    commit("SET_PREMIUM");
  },
};

export const mutations = {
  SET_USER(state, user) {
    state.User = user;
  },
  SET_USERID(state, userid) {
    state.UserId = userid;
    // Save data to sessionStorage
    sessionStorage.setItem("userid", userid);
  },
  SET_LOGGEDINUSER(state, isloggedin) {
    state.loggedinuser = isloggedin;
  },
  SET_PREMIUM(state) {
    state.User.isPremium = true;
  },
};
export const getters = {
  isloggedin(state) {
    try {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          state.loggedinuser = true;
          return true;
        } else {
          state.loggedinuser = false;
          return false;
        }
      });
      return state.loggedinuser;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};

<template>
  <v-container>
    <v-snackbar v-model="snackbar" color="error" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <FormulateForm class="mt-5" v-model="User">
      <div class="form">
        <FormulateInput
          name="email"
          type="email"
          placeholder="Email address"
          validation="required|email"
        />

        <FormulateInput
          name="password"
          type="password"
          placeholder="Your password"
          validation="required"
        />
        <v-btn
          @click="login"
          class="btn-block mx-auto"
          type="submit"
          color="primary"
        >
          Login</v-btn
        >
        <br />
        <p class="mt-5">
          Forgot your password? Contact us on fixxerupperuk@gmail.com
        </p>
      </div>
    </FormulateForm>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      User: {},
      snackbar: false,
      text: "Login unsuccessful, please try again",
      timeout: 2000,
    };
  },
  methods: {
    login() {
      this.$store.dispatch("loginUser", this.User).then((res) => {
        console.log(res);
        if (res === "success") {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push("/");
          }
        } else {
          this.snackbar = true;
        }
      });
    },
  },
};
</script>

<style></style>

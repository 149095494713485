import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#14213D",
        secondary: "#FCA311",
        accent: "#58A4B0",
        error: "#FF5252",
        info: "#2196F3",
        success: "#1EA896",
        warning: "#D43545",
      },
    },
  },
});

import Vue from "vue";
import Vuex from "vuex";
import * as search from "@/store/modules/search.js";
import * as payment from "@/store/modules/payments.js";
import * as auth from "@/store/modules/auth.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    search, // search
    payment, // payment
    auth, // registration
  },
});

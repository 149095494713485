import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueFormulate from "@braid/vue-formulate";
import "./assets/formulate.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";

Vue.use(VueFormulate);
Vue.use(VueAxios, axios);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
